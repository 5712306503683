import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BASE_URL } from "../Apis";

function PremiumSites(props) {
  const premeiumData = [
    {
      img: "https://tamashabook.com/storage/app/public/uploads/vO7Wl8aJ8i7rueKQpvlOKoxDo0IEzxkkYpMvzMgU.jpg",
      heading: "UP247.IN",
      subheading: "UP247",
    },
    {
      img: "https://tamashabook.com/storage/app/public/uploads/c74nMEKgAl8mO0BfOUFGKhgHnECrKf1Ic6TGQwCZ.jpg",
      heading: "UP365.IN",
      subheading: "UP365",
    },
    {
      img: "https://tamashabook.com/storage/app/public/uploads/xZiSzgjUiXKnzfcL40jOcQGKkhqLXha5pCTTxcuh.jpg",
      heading: "DIAMONDEXCH9",
      subheading: "DIAMONDE",
    },
    {
      img: "https://tamashabook.com/storage/app/public/uploads/skn4vDimgpntAPC5ujMSCfRoKPWkKKi9d5IScSJB.jpg",
      heading: "God777.in",
      subheading: "God777",
    },
    {
      img: "https://tamashabook.com/storage/app/public/uploads/llOWmwkVFMihaoATvRWscXxWvSKZ6PsJbwZAVFnt.jpg",
      heading: "Silverexch.Com",
      subheading: "Silver",
    },
    {
      img: "https://tamashabook.com/storage/app/public/uploads/84QRqMzCJss9q59DOxcRJSZTRx7kfKAHrMyS5oIY.jpg",
      heading: "d247.com",
      subheading: "D247",
    },
  ];
  return (
    <section className="space">
      <Container>
        <Row>
          <Col md="12">
            <div className="text-center">
              <h4 className="colorChangeText heading">All PREMIUM SITES</h4>
            </div>
          </Col>
        </Row>
        <Row className="gy-4">
          {props.data.map((item, index) => {
            return (
              <Col md="4" lg="3" sm="6" className="col-6" key={index}>
                <div className="card-style h-100">
                  <div className="card-inner d-flex align-items-center">
                    <div className="card-avater">
                      <img src={BASE_URL+ item.image} alt="" />
                    </div>
                    <div className="card-disc">
                      <h5 className="mb-0">{item.link}</h5>
                      <p className="mb-0">{item.tag}</p>
                    </div>
                  </div>
                  <div className="card-btn">
                    <a style={{ color: "white", cursor: "pointer" }}>
                      Create ID
                    </a>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
}

export default PremiumSites;
