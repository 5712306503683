import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";

import "swiper/swiper-bundle.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BASE_URL } from "../Apis";

SwiperCore.use([Autoplay, Navigation, Pagination]);

function LiveCasino(props) {
  const slides = [
    {
      id: 1,
      image:
        "	https://tamashabook.com/storage/app/public/uploads/b0jttbR5u03iGWSfdxqgmdz3oTKu08XjLsWM4M0i.png",
    },
    {
      id: 2,
      image:
        "https://tamashabook.com/storage/app/public/uploads/UJGvtS6uVsRjhSGhQlUMFO9tqBuH1aNldI6Olvfi.png",
    },
    {
      id: 3,
      image:
        "https://tamashabook.com/storage/app/public/uploads/IxNQcSHs5VsmbqN5SxEycO26kYrzHU1qDncCu8X6.png",
    },
    {
      id: 4,
      image:
        "https://tamashabook.com/storage/app/public/uploads/n9kCSCBpFm5K0OO5ZXu2EcdBVvPFiJJPpcZRGUYE.png",
    },
    {
      id: 5,
      image:
        "https://tamashabook.com/storage/app/public/uploads/DNoKZEDJru1yWOekEPNjQWHYzuDYsdElMCEl7nqF.png",
    },
    {
      id: 6,
      image:
        "https://tamashabook.com/storage/app/public/uploads/n9kCSCBpFm5K0OO5ZXu2EcdBVvPFiJJPpcZRGUYE.png",
    },
    {
      id: 7,
      image:
        "https://tamashabook.com/storage/app/public/uploads/e0rbgRKevUzGl7oFYtLDgn4p1OLreRJ0Cz788lGm.png",
    },
  ];
  return (
    <>
      <section className="space casino">
        <Container fluid>
          <Row>
            <Col md="12">
              <div className="">
                <h4 className="heading">Live Casino</h4>
              </div>
            </Col>
          </Row>
        </Container>
        <Swiper
          spaceBetween={0}
          slidesPerView={6}
          loop={true}
          autoplay={{ delay: 4000 }}
          breakpoints={{
            0: {
              slidesPerView: 4,
              transitionDuration: 2000,
            },
            991: {
              slidesPerView: 7,
              transitionDuration: 2000,
            },
          }}
        >
          {props.data.map((slide) => (
            <SwiperSlide key={slide.id}>
              <div className="launch-card">
                <div className="launch-thumb">
                  <Link to={slide.link}>
                    <img src={BASE_URL+ slide.image} alt={`Slide ${slide.id}`} />
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </>
  );
}

export default LiveCasino;
