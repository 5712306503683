import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BASE_URL } from "../Apis";

function Partner(props) {
  const Partnerimg = [
    {
      img: "https://tamashabook.com/storage/app/public/uploads/fsx23QseOHniOnFReJwQisNK0hkAFYNieTjv1hkI.png",
    },
    {
      img: "https://tamashabook.com/storage/app/public/uploads/LSoQ1zw0DbSdliYeC6uXyDY30wiTXzRlxjTBZiiE.png",
    },
    {
      img: "https://tamashabook.com/storage/app/public/uploads/olS3sv8EvXTDEBpfNIc3leJfrc50ppRJhIxdnXII.png",
    },
    {
      img: "https://tamashabook.com/storage/app/public/uploads/fsx23QseOHniOnFReJwQisNK0hkAFYNieTjv1hkI.png",
    },
    {
      img: "https://tamashabook.com/storage/app/public/uploads/LSoQ1zw0DbSdliYeC6uXyDY30wiTXzRlxjTBZiiE.png",
    },
    {
      img: "https://tamashabook.com/storage/app/public/uploads/olS3sv8EvXTDEBpfNIc3leJfrc50ppRJhIxdnXII.png",
    },
    {
      img: "https://tamashabook.com/storage/app/public/uploads/fsx23QseOHniOnFReJwQisNK0hkAFYNieTjv1hkI.png",
    },
    {
      img: "https://tamashabook.com/storage/app/public/uploads/LSoQ1zw0DbSdliYeC6uXyDY30wiTXzRlxjTBZiiE.png",
    },
    {
      img: "https://tamashabook.com/storage/app/public/uploads/olS3sv8EvXTDEBpfNIc3leJfrc50ppRJhIxdnXII.png",
    },
  ];
  return (
    <>
      <section className=" py-md-3 pt-0 pb-2 bg-white">
        <Container>
          <Row>
            <Col md="12">
              <div className="text-center">
                <h4 className="heading text-dark">OUR PARTNERS</h4>
              </div>
            </Col>
            <Col md="12">
              <div className="casio-inner">
                <ul className="justify-content-center d-flex flex-wrap list-unstyled">
                  {props.data.map((item, index) => {
                    return (
                      <li>
                        <img src={BASE_URL+ item.image} alt="" />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Partner;
