import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import miimg from "../image/Mi.png";
import cskimg from "../image/Csk.png";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import axios from 'axios'
import Spinner from 'react-bootstrap/Spinner';

import "swiper/swiper-bundle.css";
import { Link } from "react-router-dom";
import { BASE_URL, BASE_URL_2, BASE_URL_3 } from "../Apis";

SwiperCore.use([Autoplay, Pagination]);

function ScoreCard() {
  const navigate = useNavigate();

  const [loader, setloader] = React.useState(true)

  const [data, setdata] = React.useState([])

  React.useEffect(() => {
    // setInterval(fetch_data(),5000)
    const interval = setInterval(() => {
      fetch_data()
      // recent_fetch_data()
    }, 20000);
    return () => clearInterval(interval);
  }, [])
  React.useEffect(() => {
    fetch_data()
    // recent_fetch_data()
  }, [])


  let fetch_data = async () => {
    // console.log("shaun")
    await axios.get(BASE_URL_3 + "home_list").then((result) => {
      // console.log("RESULT NEW", result.data.data)
      if (result.data.data.status == true) {
        setdata(result.data.data.data)
      } else {
        // setdata([])
      }
      setloader(false)
    }).catch(e => {
      // setdata([])
    });

  }
  const [recent_data, setrecent_data] = React.useState([])
  let recent_fetch_data = async () => {
    // console.log("shaun")
    await axios.get(BASE_URL_3 + "recent_matches").then((result) => {
      // console.log("RESULT NEW", result.data.data)
      if (result.data.data.status == true) {
        setrecent_data(result.data.data.data)
      } else {
        // setrecent_data([])
      }
      setloader(false)
    }).catch(e => {
      // setrecent_data([])
    });

  }


  const gotoScoreboard = async (id, status) => {
    // alert(id)
    localStorage.setItem("MATCHID", id)
    navigate("/scorebaord/" + status)

    // if (status == "Live" || status == "Completed") {
    //   navigate("/scorebaord/" + status)
    // } else {
    //   alert("Match Not Started Yet")
    // }
  }


  // alert(new Date())

  const [timeLeft, settimeLeft] = React.useState("")

  const calculateTimeLeft = (myDate, myTime) => {
    var currentTime = new Date()
    var year = currentTime.getFullYear()
    var timeofmatch = convertTime12to24(myTime)
    var dateofmatch = myDate.split("-")
    var countDownDate = new Date(dateofmatch[1] + " " + dateofmatch[0] + "," + year + " " + timeofmatch).getTime();
    console.log(countDownDate)

    var now = new Date().getTime();
    var timeleft = countDownDate - now;

    var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    var hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
    return days + "D : " + hours + "H : " + minutes + "M ";
    // settimeLeft(days+" days : "+hours+" hours : "+minutes+" : "+seconds+" seconds ")

  }

  const convertTime12to24 = (time12h) => {

    let modifier;
    let time;

    if (time12h.includes('A')) {
      // Found world
      modifier = "AM"
      time = time12h.split("AM")[0]
    } else {
      modifier = "PM"
      time = time12h.split("PM")[0]
    }

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    // console.log("S")
    return `${hours}:${minutes}`;
  }

  return (
    <>
      {loader == false ?
        <section className="space scorecard mt-4">
          <Container fluid>

            <Row className="">
              <Swiper
                className=""
                loop={true}
                // pagination={{ clickable: true }}
                spaceBetween={50}
                slidesPerView={2}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    transitionDuration: 2000,
                  },
                  991: {
                    slidesPerView: 2,
                    transitionDuration: 2000,
                  },
                }}
              // effect="fade"
              // autoplay={{ delay: 4000 }}
              >
                {
                  data && data.length > 0 ?
                    data.map((item, index) => {

                      return (
                        <SwiperSlide className="">
                          <div onClick={() => gotoScoreboard(item.match_id, item.match_status)} className=" ">
                            <div style={{background:"#29343ed9"}} className="text-white rounded-2 p-md-2 p-1 align-items-center overflow-hidden">
                              <Col md="12" className="">
                                <div className="d-flex mb-2 justify-content-between align-items-center text-white">
                                  <div className="scorecardHeading mt-2 text-white">
                                    <h5 className="mb-0 text-white">
                                      {item.series} , {item.matchs}
                                    </h5>
                                    <p className="mb-0" style={{color:"white"}}>
                                      {
                                        item.match_date.split("-")[0] == new Date().getDate() ? "Today, " + item.match_time : item.match_date
                                      }
                                    </p>
                                  </div>


                                  <div className={item.match_status == "Live" ? "text-white liveBtn m-0 d-flex align-items-center gap-1" : "text-white upcomingBtn m-0 d-flex align-items-center gap-1"}>
                                    {item.match_status == "Live" ?
                                      <span className="liveDot"></span> : null}

                                    <span style={{ fontSize: "12px" }}>
                                      {item.match_status}
                                    </span>
                                  </div>

                                </div>
                                <div className="border-bottom"></div>
                              </Col>
                              {/* <Col md="12">
                                <Row className="align-items-center mt-2">
                                  <Col xs="5">
                                    <div className="scorebox">
                                      <div className="d-flex gap-1 align-items-center">
                                        <img style={{ width: 70, height: 70 }} src={item.team_a_img} alt="" />

                                        <div className="score">
                                          <h6 className="mb-0">{item.team_a_short}</h6>
                                        </div>



                                      </div>
                                    </div>
                                  </Col>
                                  <Col xs="2">
                                    <div className="arrowicon text-center">
                                      <i class="fa-solid fa-arrow-right-arrow-left t-purple "></i>
                                    </div>
                                  </Col>
                                  <Col xs="5">
                                    <div className="scorebox text-end">
                                      <div className="d-flex gap-1 justify-content-end align-items-center">


                                        <div className="score">
                                          <h6 className="mb-0">{item.team_b_short}</h6>
                                        </div>

                                        <img style={{ width: 70, height: 70 }} src={item.team_b_img} alt="" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md="6" className="d-flex align-items-center" >
                                    <div className="d-flex align-items-center justify-content-start ">
                                      <h5 className="team text-start">{item.team_a}</h5>
                                    </div>
                                  </Col>
                                  <Col md="6" className="d-flex align-items-center justify-content-end">
                                    <div className="d-flex align-items-center justify-content-end ">
                                      <h5 className="team text-end">{item.team_b}</h5>
                                    </div>
                                  </Col>
                                  <div className="border-bottom"></div>
                                </Row>
                              </Col> */}

                              <Col md="12">
                                <Row className="align-items-center mt-2 " 
                                // style={{ padding: "10px" }}
                                >
                                  <Col xs="5">
                                    <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                                      <img style={{ width: 70, height: 70, justifyContent: "center", alignSelf: "center" }} className="iplTeamimg" src={item.team_a_img} alt="" />
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignContent: "center", marginTop: 4 }}>
                                      <h6 className="iplTeamname">{item.team_a}</h6>
                                      <p>{item.team_a_scores ? item.team_a_scores : "-"} {item.team_a_over ? "( "+item.team_a_over+" )" : ""}</p>
                                    </div>
                                  </Col>
                                  <Col xs="2">
                                    <div className="arrowicon text-center">
                                      <i class="fa-solid fa-arrow-right-arrow-left t-white "></i>
                                    </div>
                                  </Col>
                                  <Col xs="5">
                                    <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                                      <img style={{ width: 70, height: 70, justifyContent: "center", alignSelf: "center" }} className="iplTeamimg" src={item.team_b_img} alt="" />
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignContent: "center", marginTop: 4 }}>
                                      <h6 className="iplTeamname">{item.team_b}</h6>
                                      <p>{item.team_b_scores ? item.team_b_scores : "-"} {item.team_b_over ? "( "+item.team_b_over+" )" : ""}</p>
                                    </div>
                                  </Col>

                                </Row>
                              </Col>
                              <div className="border-bottom"></div>

                              <Col md="12" className="mt-3">
                                <div className="runReq mt-3 d-flex align-items-center justify-content-between">
                                  <h6 className="runneed "  style={{color:"white"}}>
                                    {item.result ? item.result : item.venue}
                                    <p className="mb-0 ">
                                      {
                                      item.match_status == "Live" ?
                                        ""
                                        :
                                        item.match_status == "Finished" ?
                                        ""
                                        :
                                        calculateTimeLeft(item.match_date, item.match_time)
                                      }
                                    </p>
                                  </h6>

                                  <div className="winPerc d-flex align-items-center justify-content-between gap-2">
                                    <h6 className="t-white">
                                      {item.fav_team}

                                    </h6>
                                    <h6 className="percenBox">{item.min_rate ? item.min_rate.toString().split(".")[1] : 0}</h6>

                                    <h6 className="percenBox">{item.max_rate ? item.max_rate.toString().split(".")[1] : 0}</h6>
                                  </div>
                                </div>
                              </Col>
                            </div>
                          </div>
                        </SwiperSlide>
                      )
                    })

                    :
                    null
                }
              </Swiper>
            </Row>
          </Container>
        </section>
        :
        <section className="space scorecard mt-4 d-flex justify-content-center">

          <Spinner animation="grow" variant="light" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </section>
      }
    </>

  );
}

export default ScoreCard;
