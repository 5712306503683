import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  EffectFade,
  Autoplay,
  Navigation,
  Pagination,
} from "swiper";

import "swiper/swiper-bundle.css";

import { Link } from "react-router-dom";
import Celebrities from "./Celebrities";
import { BASE_URL } from "../Apis";

SwiperCore.use([EffectFade, Autoplay, Navigation, Pagination]);

function OurPromotion(props) {
  const slides = [
    {
      id: 1,
      image:
        "https://tamashabook.com/storage/app/public/uploads/XxDaiGh5EU1Q7ETxjZOMuMXDkfUO4PoIvxTWdowo.jpg",
    },
    {
      id: 2,
      image:
        "https://tamashabook.com/storage/app/public/uploads/CQ1cBkp0tV331bGdbogtYTPULkvopDPQ9t4npvJ7.jpg",
    },
    {
      id: 3,
      image:
        "https://tamashabook.com/storage/app/public/uploads/XxDaiGh5EU1Q7ETxjZOMuMXDkfUO4PoIvxTWdowo.jpg",
    },
    {
      id: 4,
      image:
        "https://tamashabook.com/storage/app/public/uploads/w8xyfVNDZdNTRrMkMTAhbUwhsGqcJYI73fp6w5pV.png",
    },
    {
      id: 5,
      image:
        "https://tamashabook.com/storage/app/public/uploads/3krJiRnu8RiVpnGDHiBHbiJte9DhzwxoNfthhKd5.jpg",
    },
    {
      id: 6,
      image:
        "https://tamashabook.com/storage/app/public/uploads/XxDaiGh5EU1Q7ETxjZOMuMXDkfUO4PoIvxTWdowo.jpg",
    },
    {
      id: 7,
      image:
        "https://tamashabook.com/storage/app/public/uploads/CQ1cBkp0tV331bGdbogtYTPULkvopDPQ9t4npvJ7.jpg",
    },
  ];
  return (
    <section className="new-videohSec pt-md-2 pb-md-2 py-2 scroll-bg">
      <Container fluid>
        <Row>
          <Col md="12">
            <div className="d-flex align-items-center justify-content-center">
              <h4
                className="heading bg-white my-2 rounded-4 text-dark text-center p-2 p-md-3 "
                style={{ width: "max-content" }}
              >
                OUR PROMOTION
              </h4>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="px-md-0">
        <Swiper
          spaceBetween={0}
          slidesPerView={6}
          loop={true}
          autoplay={{ delay: 4000 }}
          breakpoints={{
            0: {
              slidesPerView: 3,
              transitionDuration: 2000,
            },
            991: {
              slidesPerView: 7,
              transitionDuration: 2000,
            },
          }}
        >
          {props.data.map((slide) => (
            <SwiperSlide key={slide.id}>
              <div className="launch-card">
                <div className="launch-thumb">
                  <Link to={slide.link}>
                    <img
                      src={BASE_URL + slide.image}
                      alt={`Slide ${slide.id}`}
                    />
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </section>
  );
}

export default OurPromotion;
