import React, { useState, useEffect } from "react";

import Header from "../Component/Header";
import Banner from "../Component/Banner";
import Casino from "../Component/Casino";
import LiveCasino from "../Component/LiveCasino";
import FantasyGame from "../Component/FantasyGame";
import SlotGames from "../Component/SlotGames";
import PremiumSites from "../Component/PremiumSites";
import HowtoStart from "../Component/HowtoStart";
import Offer from "../Component/Offer";
import Partner from "../Component/Partner";
import OurPromotion from "../Component/OurPromotion";
import FooterSec from "../Component/FooterSec";
import PaymentSection from "../Component/PaymentSection";
import ScoreCard from "../Component/ScoreCard";
import { getBearerToken, getWithoutBearerToken } from "../Apis";
import Celebrities from "../Component/Celebrities";

function Home() {

  
  const [data, setdata] = useState(null)
  const [image_data, setimage_data] = useState([])
  useEffect(() => {
    fetch_data()
  }, [])


  const fetch_data = async () => {
    getWithoutBearerToken("setting").then(result => {

      setdata(result.data)

    })
    getWithoutBearerToken("section").then(result => {

      setimage_data(result.data)

    })

  }


  return (

    data && image_data && image_data.length > 0?
      <>

        <Header data={data}  logo= {image_data.filter(i => i.type=="logo")[0]}/>
        <Banner data = {image_data.filter(i => i.type=="banner")}/>
        <ScoreCard />
        <Casino data = {image_data.filter(i => i.type=="our_casino")}/>
        <LiveCasino data = {image_data.filter(i => i.type=="live_casino")}/>
        <FantasyGame data = {image_data.filter(i => i.type=="fantasy_game")}/>
        <SlotGames data = {image_data.filter(i => i.type=="slot_game")}/>
        <PremiumSites data = {image_data.filter(i => i.type=="premium_site")}/>
        <HowtoStart data = {image_data.filter(i => i.type=="how_to_start")}/>
        <Offer data = {image_data.filter(i => i.type=="service_offer")}/>
        <Partner data = {image_data.filter(i => i.type=="partner")}/>
        <OurPromotion data = {image_data.filter(i => i.type=="promotion")}/>
        <Celebrities data = {image_data.filter(i => i.type=="influencer")}/>

        <FooterSec data={data} responsible_gaming = {image_data.filter(i => i.type=="responsible_gaming")}/>
        <PaymentSection data = {image_data.filter(i => i.type=="payment_method")}/>
      </>
      : null
  );
}

export default Home;
