import axios from 'axios'

// export const BASE_URL = "http://localhost:8000/";
export const BASE_URL = "https://tamashabook.com:8000/";
export const BASE_URL_2 = "https://tamashabook.com:6001/";
export const BASE_URL_3 = "https://tamashabook.com:8111/";
// export const BASE_URL_3 = "http://194.163.34.157:8111/";

export const getWithoutBearerToken = async (endpoint) => {
    
    return await axios.get(BASE_URL + endpoint).then((result) => {
        return result.data
    }).catch(e => {
        return 500
    });
}
