import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import facebook from "../image/svg/facebook.svg";
import telegram from "../image/svg/Telegram.svg";

import twitter from "../image/svg/twitter.svg";
import youtube from "../image/svg/youtube.svg";
import whatsapp from "../image/svg/whatsapp.svg";
import { BASE_URL } from "../Apis";

function FooterSec(props) {
  const gaming = [
    {
      img: "https://tamashabook.com/storage/app/public/uploads/wZPZ2hKlA3a8e6rVlaeSrR0av4I2UPUfWcTUu3d2.webp",
    },
    {
      img: "https://tamashabook.com/storage/app/public/uploads/uFrF5eRynRE24BXgdwdfj5ZyJYumaGIJtg5ag3z7.webp",
    },
    {
      img: "https://tamashabook.com/storage/app/public/uploads/d2mqGn6YXecTAmrJgqGQuhBPjKGeFKibjlYQvabV.png",
    },
    {
      img: "https://tamashabook.com/storage/app/public/uploads/HD76TUJ26nXAatyFeb5w7nqfrQjSJEMM8rk9ERzH.png",
    },
  ];
  const social = [
    {
      img: facebook,
      url: props.data.social_facebook,
    },
    {
      img: telegram,
      url: props.data.telegram,
    },
    {
      img: "https://tamashabook.com/assets/images/home/social3.svg",
      url: props.data.social_instagram,
    },
    {
      img: twitter,
      url: props.data.social_twitter,
    },

    {
      img: youtube,
      url: props.data.youtube,
    },
    {
      img: whatsapp,
      url: props.data.social_whatsapp,
    },
  ];
  return (
    <>
      <section className="footer-sec pt-2">
        <Container fluid>
          <div className="">
            <Row className="justify-content-between">
              <Col lg="4" md="12">
                <div className="casio-inner mb-lg-0 mb-0">
                  <h4 className="text-white fw-bold">Responsible gaming:</h4>
                  <ul className="gaming d-flex flex-wrap m-0 p-0">
                    {props.responsible_gaming.map((item) => {
                      return (
                        <li>
                          <Link to={item.link}>
                            <img src={BASE_URL+ item.image} alt="" />
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </Col>
              <Col lg="4" md="12">
                <div className="casio-inner">
                  <h4 className="text-white fw-bold">Follow Us:</h4>
                  <div className="casio-inner">
                    <ul className="follow d-flex flex-wrap m-0 p-0">
                      {social.map((item) => {
                        return (
                          <li>
                            <Link to={item.url}>
                              <img src={item.img} alt="" />
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="footer-bottom bg-black mt-0 ">
          <ul className="d-flex justify-content-center flex-wrap align-items-center m-0 p-0">
            <li>
              <Link to={props.data.footer_contact_us}>Contact Us</Link>
            </li>
            <li>
              <Link to={props.data.footer_promotions}>Promotions</Link>
            </li>
            <li>
              <Link to={props.data.footer_offers}>Offers</Link>
            </li>
            <li>
              <Link to={props.data.footer_tnc}>T&amp;C</Link>
            </li>
            <li>
              <Link to={props.data.footer_privacy_policy}>Privacy Policy</Link>
            </li>
          </ul>
        </div>
      </section>
      
      <div>
        <div
          className="whatsApp telegram"
          onClick={() => (window.location = "https://t.me/+917052354154")}
        >
          <img className="whatsApp_img" src={telegram} alt="" />
          <div className="circleIconPanel showMessage"></div>
        </div>
        <div
          className="whatsApp"
          onClick={() =>
            (window.location =
              "https://api.whatsapp.com/send?phone=918601294355")
          }
        >
          <img
            className="whatsApp_img"
            src="https://www.lotus365.in/static/media/whatsApp.e8642b33.svg"
            alt=""
          />
          <div className="circleIconPanel showMessage"></div>
        </div>
      </div>
    </>
  );
}

export default FooterSec;
