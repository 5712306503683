import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "./Header";
import CalculateSum from "../Formula/CalculateSum";
import FooterSec from "./FooterSec";
import PaymentSection from "./PaymentSection";
import axios from "axios";
import { BASE_URL_2, getWithoutBearerToken } from "../Apis";
import ScoreBoardExtended from "./ScoreBoardExtended";
import { useParams } from "react-router-dom";
import ScoreBoardUpcoming from "./ScoreBoardUpcoming";

function ScoreBoard() {
  const runs = [
    {
      ball: 1,
    },
    {
      ball: 2,
    },
    {
      ball: "WD",
    },
    {
      ball: 4,
    },
    {
      ball: 0,
    },
    {
      ball: 6,
    },
  ];

  const sum = CalculateSum(runs);


  // const [data, setdata] = React.useState(null)
  // const [odds_data, setodds_data] = React.useState(null)
  // const [favourable_odds, setfavourable_odds] = React.useState(null)

  // React.useEffect(() => {
  //   // setInterval(fetch_data() , 5000)
  //   const interval = setInterval(() => {
  //     fetch_data()
  //   }, 1000);
  //   return () => clearInterval(interval);
  // }, [])

  // let fetch_data = async () => {
  //   let id = localStorage.getItem("MATCHID")

  //   await axios.get(BASE_URL_2 + "fetch_match_data/" + id).then((result) => {

  //     setdata(result.data.data[0].match_data)
  //     setodds_data(result.data.data[0].match_odds)
  //     setfavourable_odds(result.data.data[0].favourable_odds)
  //     console.log("hit")
  //   }).catch(e => {
  //     setdata(null)
  //   });

  // }





  const [file_data, set_file_data] = useState(null)
  const [image_data, setimage_data] = useState([])
  useEffect(() => {
    fetch_data_new()
  }, [])


  const fetch_data_new = async () => {
    getWithoutBearerToken("setting").then(result => {

      set_file_data(result.data)

    })
    getWithoutBearerToken("section").then(result => {

      setimage_data(result.data)

    })

  }

  const {id} =useParams()


  return (
    file_data && image_data && image_data.length > 0?
      <>
        <Header data={file_data} logo={image_data.filter(i => i.type == "logo")[0]} />
        
        {
          id == "live" 
          ?
          <ScoreBoardExtended />
          :
          id == "Upcoming" 
          ?
          <ScoreBoardUpcoming/>
          :
          <ScoreBoardExtended/>

        }
        
        {/* <FooterSec /> */}
        {/* <PaymentSection /> */}
        <FooterSec data={file_data} responsible_gaming={image_data.filter(i => i.type == "responsible_gaming")} />
        <PaymentSection data={image_data.filter(i => i.type == "payment_method")} />
      </>
      :
      null
  );
}

export default ScoreBoard;
