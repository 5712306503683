import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";
import { BASE_URL } from "../Apis";

function Header(props) {
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };
  return (
    <>
      <section className="bg-black">
        <Container>
          <Row>
            <Col md="12">
              <Marquee speed={80} gradient={false}>
                <div className="text-white">
                 {props.data ? props.data.marquee : ""}
                </div>
              </Marquee>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <div
          className={`header-area header-defult header-style-one ${
            isSticky ? "fixed-top bg-black" : ""
          }`}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-5 col-5">
                <div className="nav-logo d-flex justify-content-between align-items-center">
                  <Link to="/">
                    <img
                      src={BASE_URL+props.logo.image}
                      alt="logo"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-xl-6 col-lg-8 col-md-8 col-sm-6 col-xs-6 d-none">
                <nav className="main-nav float-end">
                  <div className="inner-logo d-xl-none text-center">
                    <Link to="/">
                      <img
                        src={BASE_URL+props.logo.image}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="inner-contact-options d-xl-none d-inline-flex flex-column px-3">
                    <Link
                      to={props.data ? props.data.login_link : ""}
                      className="btn-outlined-round"
                    >
                      Login
                    </Link>
                    <Link
                      href={props.data ? props.data.register_link : ""}
                      className="btn-outlined-round"
                    >
                      Register
                    </Link>
                  </div>
                </nav>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-7 col-7">
                <div className="nav-right float-end">
                  <Link
                    to={props.data ? props.data.login_link : ""}
                    className="btn-outlined-round bgcolorOne"
                  >
                    Login
                  </Link>
                  <Link
                    to={props.data ? props.data.register_link : ""}
                    className="btn-outlined-round bgcolorTwo"
                  >
                    Register
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Header;
