import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  EffectFade,
  Autoplay,
  Navigation,
  Pagination,
} from "swiper";

import "swiper/swiper-bundle.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BASE_URL } from "../Apis";

SwiperCore.use([EffectFade, Autoplay, Navigation, Pagination]);

function FantasyGame(props) {
  const slides = [
    {
      id: 1,
      image:
        "https://tamashabook.com/storage/app/public/uploads/yqpf7v4t5EImp2t3ZmQ3Q8ivcvrJ5s1BWbcSzdLx.png",
    },
    {
      id: 2,
      image:
        "https://tamashabook.com/storage/app/public/uploads/B3j142vSiDBiWPBMJYrlAZJmNKneylIajIbrxXuO.png",
    },
    {
      id: 3,
      image:
        "https://tamashabook.com/storage/app/public/uploads/T8RmsdTUXMrAcjVvrZPy0xIKLNVJR7j2liMJilQJ.png",
    },
    {
      id: 4,
      image:
        "https://tamashabook.com/storage/app/public/uploads/uW3hXtyMkBukbDQAWkVR7jPhOYuM2mGOngnPiu1j.png",
    },
    {
      id: 5,
      image:
        "https://tamashabook.com/storage/app/public/uploads/lLhupuZaYn8LjGIaHbmAsnkeN232ZOS3FQ55rInT.png",
    },
    {
      id: 6,
      image:
        "https://tamashabook.com/storage/app/public/uploads/01aQd9bVP565waoO39jaDiZAZO5FesXRlpaHB4g8.png",
    },
    {
      id: 7,
      image:
        "https://tamashabook.com/storage/app/public/uploads/yqpf7v4t5EImp2t3ZmQ3Q8ivcvrJ5s1BWbcSzdLx.png",
    },
  ];
  return (
    <>
      <section className="space casino">
        <Container fluid>
          <Row>
            <Col md="12">
              <div className="">
                <h4 className="heading">Fantasy Game</h4>
              </div>
            </Col>
          </Row>
        </Container>
        <Swiper
          spaceBetween={0}
          slidesPerView={6}
          loop={true}
          autoplay={{ delay: 4000 }}
          breakpoints={{
            0: {
              slidesPerView: 4,
              transitionDuration: 2000,
            },
            991: {
              slidesPerView: 7,
              transitionDuration: 2000,
            },
          }}
        >
          {props.data.map((slide) => (
            <SwiperSlide key={slide.id}>
              <div className="launch-card">
                <div className="launch-thumb">
                  <Link to={slide.link}>
                    <img src={BASE_URL+slide.image} alt={`Slide ${slide.id}`} />
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </>
  );
}

export default FantasyGame;
