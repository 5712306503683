import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  EffectFade,
  Autoplay,
  Navigation,
  Pagination,
} from "swiper";

import "swiper/swiper-bundle.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BASE_URL } from "../Apis";

SwiperCore.use([EffectFade, Autoplay, Navigation, Pagination]);

function SlotGames(props) {
  const slides = [
    {
      id: 1,
      image:
        "https://tamashabook.com/storage/app/public/uploads/l8cd055STJpBA0qFvyOFtbwEN7NoGaQkEfyIwc3Y.png",
    },
    {
      id: 2,
      image:
        "https://tamashabook.com/storage/app/public/uploads/S9SOjsh4rY5dfygbQ1BjGOzLi8w1iQqZ93zx8Fnv.png",
    },
    {
      id: 3,
      image:
        "https://tamashabook.com/storage/app/public/uploads/RhkkFc6uF3GkpmfaXgN419LDM8EpJwwYKbpMTZ6E.png",
    },
    {
      id: 4,
      image:
        "https://tamashabook.com/storage/app/public/uploads/EGzLmYBnPnXTfdi97u2diPBgmaFIU0q8HHXnNtSn.png",
    },
    {
      id: 5,
      image:
        "https://tamashabook.com/storage/app/public/uploads/jcEPheNlvpOEpet8E91PDsmOJufL1YdUEnbGmAZ8.png",
    },
    {
      id: 6,
      image:
        "https://tamashabook.com/storage/app/public/uploads/OVp1HM8VLfHGFtZZ58m0A7kS7wt9oa9gvT3kL56n.png",
    },
    {
      id: 7,
      image:
        "https://tamashabook.com/storage/app/public/uploads/RhkkFc6uF3GkpmfaXgN419LDM8EpJwwYKbpMTZ6E.png",
    },
  ];
  return (
    <>
      <section className="space casino">
        <Container fluid>
          <Row>
            <Col md="12">
              <div className="">
                <h4 className="heading">Slot Games</h4>
              </div>
            </Col>
          </Row>
        </Container>
        <Swiper
          spaceBetween={0}
          slidesPerView={6}
          loop={true}
          autoplay={{ delay: 4000 }}
          breakpoints={{
            0: {
              slidesPerView: 4,
              transitionDuration: 2000,
            },
            991: {
              slidesPerView: 7,
              transitionDuration: 2000,
            },
          }}
        >
          {props.data.map((slide) => (
            <SwiperSlide key={slide.id}>
              <div className="launch-card">
                <div className="launch-thumb">
                  <Link to={slide.link}>
                    <img src={BASE_URL +slide.image} alt={`Slide ${slide.id}`} />
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </>
  );
}

export default SlotGames;
