import React from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SelectLangContent from "./SelectLangContent";
import { BASE_URL } from "../Apis";

function HowtoStart(props) {
  const [tag, settag] = useState("English");
  const [data, setdata] = useState([])
  React.useEffect(() => {
    setdata(props.data.filter(i => i.tag == "English"))
    
  }, [])
  
  const handleLanguageChange = (e) => {
    setdata(props.data.filter(i => i.tag == e))
  };
  return (
    <>
      <section className="space">
        <Container>
          <Row className="pb-3 gy-4">
            <Col md="8" className="col-6">
              <div className="">
                <h4 className="heading">How To Start</h4>
              </div>
            </Col>
            <Col md="4" className="col-6">
              <div className="lng-drop d-flex justify-content-end">
                <select
                  id="hw-to-select"
                  value={tag}
                  onChange={(e) => handleLanguageChange(e.target.value)}
                  className="form-select fit-content"
                >
                  <option value="English">English</option>
                  <option value="Hindi">Hindi</option>
                  <option value="Kannada">Kannada</option>
                  <option value="Telugu">Telugu</option>
                </select>
              </div>
            </Col>
          </Row>

          {/* {selectedLanguage === "EN" && (
            <Row id="EN" className=" gy-4 how-to-imgSec ">
              <div className="col-lg-3 col-sm-4 col-6">
                <div className="card-style">
                  <a href=""></a>
                  <img
                    src="https://tamashabook.com/storage/app/public/uploads/JneWeberZdYy6913avQ1OAGEvVHQXI9qNhiXl5yW.png"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
                <div className="card-style">
                  <a href=""></a>
                  <img
                    src="https://tamashabook.com/storage/app/public/uploads/QMU3Ylufybo5NBfX3v9VmagVDf6giClf4eItcnAI.png"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
                <div className="card-style">
                  <a href=""></a>
                  <img
                    src="https://tamashabook.com/storage/app/public/uploads/ba0jBIrQ8RHbaFyLsr9fwO4ji40SbkcYj4CmQB3S.png"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-3 col-sm-4 col-6">
                <div className="card-style">
                  <a href=""></a>
                  <img
                    src="https://tamashabook.com/storage/app/public/uploads/WMMMNDvkwL4uv0R685kwabMXGh1tuAn8yceeQARn.png"
                    className="img-fluid"
                  />
                </div>
              </div>
            </Row>
          )} */}
          <div className="mb-3">

            <Row id={props.id} className=" gy-4 how-to-imgSec">

              {
                data && data.length > 0
                  ?
                  data.map((item, index) => (
                    <div className="col-lg-3 col-sm-4 col-6">
                      <div className="card-style">
                        <a href={item.link}></a>
                        <img src={BASE_URL+ item.image} className="img-fluid" />
                      </div>
                    </div>
                  ))

                  :
                  null
              }


            </Row>


            {/* {selectedLanguage === "EN" && (
              <SelectLangContent
                id="EN"
                img1="https://tamashabook.com/storage/app/public/uploads/JneWeberZdYy6913avQ1OAGEvVHQXI9qNhiXl5yW.png"
                img2="https://tamashabook.com/storage/app/public/uploads/QMU3Ylufybo5NBfX3v9VmagVDf6giClf4eItcnAI.png"
                img3="https://tamashabook.com/storage/app/public/uploads/ba0jBIrQ8RHbaFyLsr9fwO4ji40SbkcYj4CmQB3S.png"
                img4="https://tamashabook.com/storage/app/public/uploads/WMMMNDvkwL4uv0R685kwabMXGh1tuAn8yceeQARn.png"
              />
            )}
            {selectedLanguage === "HN" && (
              <SelectLangContent
                id="HN"
                img1="https://tamashabook.com/storage/app/public/uploads/eBlWnBJE7qerQcTSsXg3BK8EBAfeqL80B9d3KJMr.png"
                img2="https://tamashabook.com/storage/app/public/uploads/JmzBd8oVGG1DiKokaH5TMkL1p8m0umwOACH0VII0.png"
                img3="https://tamashabook.com/storage/app/public/uploads/pi8WnmVfZrHRGbPeF0reVySbpiqQvhe5ZIskUWPX.png"
                img4="https://tamashabook.com/storage/app/public/uploads/Kc2nCDjhDxnhnOQL9y0bPzBfs9sowH1HrwKI5xnX.png"
              />
            )} */}
          </div>
        </Container>
      </section>
    </>
  );
}

export default HowtoStart;
