import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  EffectFade,
  Autoplay,
  Navigation,
  Pagination,
} from "swiper";

import "swiper/swiper-bundle.css";
import { BASE_URL } from "../Apis";

SwiperCore.use([EffectFade, Autoplay, Navigation, Pagination]);

function Banner(props) {
  const slides = [
    {
      id: 1,
      image:
        "https://tamashabook.com/storage/app/public/uploads/CckhWIwdhC97Ggpm7pC8G5SwXmgIZqLgmsZog1xY.png",
    },
    {
      id: 2,
      image:
        "https://tamashabook.com/storage/app/public/uploads/exzOcbCNEr2qEYlbd5nONhHeF7gQkrBBS2FoXjj6.png",
    },
    {
      id: 3,
      image:
        "https://tamashabook.com/storage/app/public/uploads/CckhWIwdhC97Ggpm7pC8G5SwXmgIZqLgmsZog1xY.png",
    },
    {
      id: 4,
      image:
        "https://tamashabook.com/storage/app/public/uploads/nDm8LVm9RstZRedCNeGlc8PwHbyKaaU4OUyI6rFJ.png",
    },
    {
      id: 5,
      image:
        "https://tamashabook.com/storage/app/public/uploads/AYpw09uB5vZD5o6RERxnLmUZPgHcQDrVfeR1y2Ov.png",
    },
    {
      id: 6,
      image:
        "https://tamashabook.com/storage/app/public/uploads/3fgXX3zihSRBWApvpyHUC8SHBjrmYYwSABjzKWP6.jpg",
    },
  ];
  return (
    <>
      <section className="bannerSection">
        <div className="">
          <Swiper
            navigation
            loop={true}
            pagination={{ clickable: true }}
            spaceBetween={50}
            slidesPerView={1}
            effect="fade"
            autoplay={{ delay: 4000 }}
          >
            {props.data.map((slide) => (
              <SwiperSlide key={slide.id}>
                <img src={BASE_URL+ slide.image} alt={`Slide ${slide.id}`} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </>
  );
}

export default Banner;
