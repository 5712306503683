import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "./Header";
import CalculateSum from "../Formula/CalculateSum";
import FooterSec from "./FooterSec";
import PaymentSection from "./PaymentSection";
import axios from "axios";
import { BASE_URL, BASE_URL_2, BASE_URL_3, getWithoutBearerToken } from "../Apis";
import screenimg from '../image/screeen.png'

const ScoreBoardUpcoming = () => {


    const [data, setdata] = React.useState(null)

    React.useEffect(() => {
        // setInterval(fetch_data() , 5000)
        const interval = setInterval(() => {
            fetch_data()
        }, 20000);
        return () => clearInterval(interval);
    }, [])
    React.useEffect(() => {
        calculateTimeLeft()
    }, [data]);

    React.useEffect(() => {
        fetch_data()
    }, [])





    const [olddata, setolddata] = useState(null)

    let fetch_data = async () => {
        let id = localStorage.getItem("MATCHID")

        await axios.get(BASE_URL_3 + "match_info/" + id).then((result) => {
            setdata(result.data.data.data)
            setolddata(result.data.data.data)

        }).catch(e => {
            setdata(olddata)
            // setdata(null)
        });

    }
    React.useEffect(() => {


    }, [])

    // var myfunc = setInterval(function () {
    //     calculateTimeLeft()
    // }, 1000)


    const [timeLeft, settimeLeft] = React.useState("")
    const calculateTimeLeft = () => {
        setInterval(function () {
            if (data) {
                console.log("x")

                var currentTime = new Date()
                var year = currentTime.getFullYear()
                var timeofmatch = convertTime12to24(data.match_time)
                var dateofmatch = data.match_date.split("-")
                var countDownDate = new Date(dateofmatch[1] + " " + dateofmatch[0] + "," + year + " " + timeofmatch).getTime();
                var now = new Date().getTime();
                var timeleft = countDownDate - now;
                var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
                var hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
                console.log("reslt", days + "D : " + hours + "H : " + minutes + "M : " + seconds + "S ")
                settimeLeft(days + "D : " + hours + "H : " + minutes + "M ")
                return days + "D : " + hours + "H : " + minutes + "M : " + seconds + "S ";
            } else {
                console.log("y")
                // settimeLeft(0 + "D : " + 0 + "H : " + 0 + "M : ")

                // return 0 + "D : " + 0 + "H : " + 0 + "M : " + 0 + "S ";
            }
        }, 1000)

    }

    const convertTime12to24 = (time12h) => {

        let modifier;
        let time;

        if (time12h.includes('A')) {
            // Found world
            modifier = "AM"
            time = time12h.split("AM")[0]
        } else {
            modifier = "PM"
            time = time12h.split("PM")[0]
        }

        let [hours, minutes] = time.split(':');

        if (hours === '12') {
            hours = '00';
        }

        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }

        // console.log("S")
        return `${hours}:${minutes}`;
    }

    return (


        data ?
            <div >
                <section className="mt-2 scoreDash">
                    <Container fluid>
                        <Row className="">


                            <Col xs="12" md="12">
                                <div className="tvimg d-flex w-100 justify-content-center">
                                    <div className="tvbox" style={{ position: "relative" }}>
                                        <img src={screenimg} className="img-fluid w-100 " alt="" />
                                        <h4 className="text-white  middleTexttv">
                                            Starts : {timeLeft ? timeLeft : ""}
                                        </h4>
                                        <div className="firstTeamScore">
                                            {/* MDX 126-10 (<span>14.5</span>) */}
                                            {/* {data.team_a} VS {data.team_b} */}
                                        </div>
                                        <div className="secondTeamScore">
                                            {/* SUR 199-10 (<span>20.0</span>) */}
                                        </div>
                                    </div>
                                </div>
                            </Col>


                            <Col xs="6">
                                <div className="bg-white mt-2 p-2 p-1 p-md-3 rounded-2 d-flex justify-content-between">
                                    <h5>{data.series} , {data.matchs}</h5>
                                </div>
                            </Col>
                            {/* <Col xs="6">
                                <div className="bg-white mt-2 p-2 p-1 p-md-3 rounded-2 d-flex justify-content-between">
                                    <h5>

                                        {timeLeft ? timeLeft : ""}

                                    </h5>
                                </div>
                            </Col> */}
                            <Col xs="6">
                                <div className="bg-white mt-2 p-2 p-1 p-md-3 rounded-2 d-flex justify-content-between">
                                    <h5>
                                        {data.team_a}
                                    </h5>
                                    <h5>VS</h5>
                                    <h5>
                                        {data.team_b}
                                    </h5>
                                </div>
                            </Col>


                            {/* <Col xs="12">
                                <div className="bg-white mt-2 p-2 p-1 p-md-3 rounded-2 d-flex justify-content-between">

                                    <h5 className="mb-0">{data['team_a']} : {data["team_a_scores"]}  (OV : {data["team_a_over"]})</h5>
                                    <h5 className="mb-0">{data['team_b']} : {data["team_b_scores"]}  (OV : {data["team_b_over"]})</h5>
                                </div>
                            </Col>
                            <Col xs="12">
                                <div className="bg-white mt-2 p-2 p-1 p-md-3 rounded-2 d-flex justify-content-between">
                                    <h5 className="mb-0">Run Rate: {data['curr_rate']}</h5>
                                    <h5 className="mb-0">Target :  {data['target']}</h5>
                                </div>
                            </Col>
                            <Col xs="12">
                                <div className="bg-white mt-2 p-2 p-1 p-md-3 rounded-2 d-flex justify-content-between">
                                    <h5 className="mb-0">Required Run Rate: {data['rr_rate']}</h5>
                                    <h5 className="mb-0">Last Wkt :  {data?.lastwicket?.player} {data?.lastwicket?.run}({data?.lastwicket?.ball})</h5>
                                </div>
                            </Col> */}
                            {/* <Col xs="12" md="12">
                                <div className="bg-white mt-2  p-1 p-md-3 rounded-2">
                                    <Row className="align-items-center ">
                                        <Col xs="6">
                                            <h5>Fav : {data?.fav_team}</h5>
                                        </Col>
                                        <Col xs="6">

                                            <div className="winPerc d-flex align-items-center justify-content-end gap-1">
                                                <h6></h6>
                                                <h6 className="percenBox">{data?.min_rate}</h6>
                                                <h6 className="percenBox">{data?.max_rate}</h6>
                                            </div>



                                        </Col>

                                    </Row>
                                </div>
                            </Col> */}
                        </Row>
                    </Container>
                </section>


                <section className="mt-2  scoreboard">
                    <Container fluid>
                        <Row className="gy-1 mb-4">
                            <Col xs="12" md="6">
                                <div className="bg-white h-100 mb-1 p-1 p-md-3 rounded-2">
                                    <table className="table scorecardTable mb-1">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "40%" }}>Batter</th>
                                                <th style={{ width: "12%" }}>R</th>
                                                <th style={{ width: "12%" }}>B</th>
                                                <th style={{ width: "12%" }}>4s</th>
                                                <th style={{ width: "12%" }}>6s</th>
                                                <th style={{ width: "12%" }}>SR</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                            {/* {data["batsman"] && data["batsman"].length > 0 ?
                                                data["batsman"].map((item, index) => (
                                                    <tr>
                                                        <td>{item.name}</td>
                                                        <td>{item.run}</td>
                                                        <td>{item.ball}</td>
                                                        <td>{item.fours}</td>
                                                        <td>{item.sixes}</td>
                                                        <td>{item.strike_rate}</td>

                                                    </tr>
                                                ))
                                                :
                                                null
                                            } */}

                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                            <Col xs="12" md="6">
                                <div className="bg-white  h-100 mb-1 p-1 p-md-3 rounded-2">
                                    <table className="table scorecardTable mb-1">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "40%" }}>Bowler</th>
                                                <th style={{ width: "12%" }}>O</th>
                                                <th style={{ width: "12%" }}>R</th>
                                                <th style={{ width: "12%" }}>Wkt</th>
                                                <th style={{ width: "12%" }}>Eco</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data?.bolwer
                                                    ?
                                                    <tr>
                                                        <td>{data?.bolwer.name}</td>
                                                        <td>{data?.bolwer.over}</td>
                                                        <td>{data?.bolwer.run}</td>
                                                        <td>{data?.bolwer.wicket}</td>
                                                        <td>{data?.bolwer.economy}</td>
                                                    </tr>

                                                    :
                                                    null

                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                            <Col xs="12" md="6">
                                <div className="bg-white mt-md-2 h-100  p-1 p-md-3 rounded-2">
                                    <table className="table scorecardTable mb-1">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "40%" }}>Yet To Bat</th>
                                                <th style={{ width: "12%" }}></th>
                                                <th style={{ width: "12%" }}></th>
                                                <th style={{ width: "12%" }}></th>
                                                <th style={{ width: "12%" }}></th>
                                                <th style={{ width: "12%" }}></th>
                                                <th style={{ width: "12%" }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>

                                                {
                                                    data["yet_to_bet"] && data["yet_to_bet"].length > 0 ?
                                                        data["yet_to_bet"].map((item, index) => (
                                                            <td>{item}</td>
                                                        ))
                                                        :
                                                        null
                                                }
                                                {/* <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td> */}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                            <Col xs="12" md="6">
                                <div className="bg-white mt-md-2 h-100  p-1 p-md-3 rounded-2">
                                    <table className="table scorecardTable mb-1">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "100%" }}>Last 6 balls</th>
                                            </tr>
                                        </thead>
                                    </table>

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>


            </div>
            :
            null


    )
}

export default ScoreBoardUpcoming