import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  EffectFade,
  Autoplay,
  Navigation,
  Pagination,
} from "swiper";

import "swiper/swiper-bundle.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BASE_URL } from "../Apis";

SwiperCore.use([EffectFade, Autoplay, Navigation, Pagination]);

function Offer(props) {
  const slides = [
    {
      id: 1,
      image:
        "https://tamashabook.com/storage/app/public/uploads/GsEVn4iDulhEwZTpxgqPZ1L4WUEGzm8rliiKuRi0.jpg",
    },
    {
      id: 2,
      image:
        "https://tamashabook.com/storage/app/public/uploads/3krJiRnu8RiVpnGDHiBHbiJte9DhzwxoNfthhKd5.jpg",
    },
    {
      id: 3,
      image:
        "https://tamashabook.com/storage/app/public/uploads/zBciQdCHyMvpLfuhZeV0wPRd38Mwbr6H9byUYjG0.jpg",
    },
    {
      id: 4,
      image:
        "https://tamashabook.com/storage/app/public/uploads/GsEVn4iDulhEwZTpxgqPZ1L4WUEGzm8rliiKuRi0.jpg",
    },
    {
      id: 5,
      image:
        "https://tamashabook.com/storage/app/public/uploads/3krJiRnu8RiVpnGDHiBHbiJte9DhzwxoNfthhKd5.jpg",
    },
    {
      id: 6,
      image:
        "https://tamashabook.com/storage/app/public/uploads/zBciQdCHyMvpLfuhZeV0wPRd38Mwbr6H9byUYjG0.jpg",
    },
  ];
  return (
    <section className="bg-white live-card pt-sm-3 pt-0 pb-2">
      <Container fluid>
        <Row>
          <Col md="12">
            <div className="text-center">
              <h4 className="heading text-dark">SERVICE & OFFER</h4>
            </div>
          </Col>
          <Col md="12">
            <div className="serviceandoffer">
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
                loop={true}
                pagination={{ clickable: true }}
                navigation
                autoplay={{ delay: 4000 }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    transitionDuration: 2000,
                  },
                  991: {
                    slidesPerView: 1,
                    transitionDuration: 2000,
                  },
                }}
              >
                {props.data.map((slide) => (
                  <SwiperSlide key={slide.id}>
                    <div className="launch-card">
                      <div className="launch-thumb">
                        <Link to={slide.link}>
                          <img
                            src={BASE_URL + slide.image}
                            alt={`Slide ${slide.id}`}
                          />
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Col>
        </Row>
      </Container>
      {/* <Swiper
        spaceBetween={0}
        slidesPerView={6}
        loop={true}
        autoplay={{ delay: 4000 }}
        breakpoints={{
          0: {
            slidesPerView: 3,
            transitionDuration: 2000,
          },
          991: {
            slidesPerView: 6,
            transitionDuration: 2000,
          },
        }}
      >
        {props.data.map((slide) => (
          <SwiperSlide key={slide.id}>
            <div className="launch-card">
              <div className="launch-thumb">
                <Link to={slide.link}>
                  <img src={BASE_URL + slide.image} alt={`Slide ${slide.id}`} />
                </Link>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper> */}
    </section>
  );
}

export default Offer;
