import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "./Header";
import CalculateSum from "../Formula/CalculateSum";
import FooterSec from "./FooterSec";
import PaymentSection from "./PaymentSection";
import axios from "axios";
import { BASE_URL, BASE_URL_2, BASE_URL_3, getWithoutBearerToken } from "../Apis";
import screenimg from '../image/screeen.png'

const ScoreBoardExtended = () => {


    const [data, setdata] = React.useState(null)

    React.useEffect(() => {
        // setInterval(fetch_data() , 5000)
        const interval = setInterval(() => {
            fetch_data()
        }, 4000);
        return () => clearInterval(interval);
    }, [])
    React.useEffect(() => {
        fetch_data()
    }, [])





    const [olddata, setolddata] = useState(null)

    let fetch_data = async () => {
        let id = localStorage.getItem("MATCHID")

        await axios.get(BASE_URL_3 + "live_match/" + id).then((result) => {
            setdata(result.data.data.data)
            setolddata(result.data.data.data)
        }).catch(e => {
            setdata(olddata)
            // setdata(null)
        });

    }

    const [time_left, settime_left] = useState(null)
    // const calculateTimeLeft = (myDate) => {

    //     let ndate = myDate.split(" ")

    //     var z = new Date(ndate[0]+" "+ndate[2]+" "+ndate[3]+" "+ndate[4]+convertTime12to24(ndate[5])+" GMT+0530")

    //     let difference = z - new Date();

    //     let timeLeft = {};

    //     if (difference > 0) {
    //       timeLeft = {
    //         days: Math.floor(difference / (1000 * 60 * 60 * 24)),
    //         hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
    //         minutes: Math.floor((difference / 1000 / 60) % 60),
    //         seconds: Math.floor((difference / 1000) % 60)
    //       };
    //     }
    //     settime_left(timeLeft)
    // }

    // const convertTime12to24 = (time12h) => {

    //     let modifier;
    //     let time;

    //     if (time12h.includes('A')) { 
    //         // Found world
    //         modifier = "AM"
    //         time=time12h.split("AM")[0]
    //     }else{
    //         modifier = "PM"
    //         time=time12h.split("PM")[0]
    //     }

    //     let [hours, minutes] = time.split(':');

    //     if (hours === '12') {
    //       hours = '00';
    //     }

    //     if (modifier === 'PM') {
    //       hours = parseInt(hours, 10) + 12;
    //     }

    //     console.log("S")
    //     return `${hours}:${minutes}`;
    //   }

    return (


        data ?
            <div >
                <section className="mt-2 scoreDash">
                    <Container fluid>
                        <Row className="">
                            <Col xs="12" md="12">
                                <div className="tvimg d-flex w-100 justify-content-center">
                                    <div className="tvbox" style={{ position: "relative" }}>
                                        <img src={screenimg} className="img-fluid w-100 " alt="" />
                                        <h4 className="text-white  middleTexttv">
                                            {
                                                data["result"]
                                                    ?
                                                    data['result']
                                                    :
                                                    data?.first_circle
                                            }
                                        </h4>
                                        
                                        <div className="firstTeamScore">
                                            {data['team_a_short']} {data?.team_a_scores}  (OV :  {data?.team_a_over} )
                                        </div>
                                        <div className="secondTeamScore">
                                            {data['team_b_short']} {data?.team_b_scores}  (OV :  {data?.team_b_over} )
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs="12">
                                <div className="bg-white mt-2 p-2 p-1 p-md-3 rounded-2 d-flex justify-content-between">
                                    {
                                        data["result"]
                                            ?
                                            <h5 className="mb-0"> {data['result']}</h5>
                                            :
                                            <h5 className="mb-0"> {data['current_inning'] == "2" ? "Runs Need " + data["run_need"] + " Balls Remaining " + data["ball_rem"] : data['toss']}</h5>
                                    }
                                </div>
                            </Col>


                            <Col xs="12">
                                <div className="bg-white mt-2 p-2 p-1 p-md-3 rounded-2 d-flex justify-content-between">

                                    <h5 className="mb-0">{data['team_a']} : {data["team_a_scores"]}  (OV : {data["team_a_over"]})</h5>
                                    <h5 className="mb-0">{data['team_b']} : {data["team_b_scores"]}  (OV : {data["team_b_over"]})</h5>
                                </div>
                            </Col>
                            <Col xs="12">
                                <div className="bg-white mt-2 p-2 p-1 p-md-3 rounded-2 d-flex justify-content-between">
                                    <h5 className="mb-0">Run Rate: {data['curr_rate']}</h5>
                                    <h5 className="mb-0">Target :  {data['target']}</h5>
                                </div>
                            </Col>
                            <Col xs="12">
                                <div className="bg-white mt-2 p-2 p-1 p-md-3 rounded-2 d-flex justify-content-between">
                                    <h5 className="mb-0">Required Run Rate: {data['rr_rate']}</h5>
                                    <h5 className="mb-0">Last Wkt :  {data?.lastwicket?.player} {data?.lastwicket?.run}({data?.lastwicket?.ball})</h5>
                                </div>
                            </Col>
                            <Col xs="12" md="12">
                                <div className="bg-white mt-2  p-1 p-md-3 rounded-2">
                                    <Row className="align-items-center ">
                                        <Col xs="6">
                                            <h5>Fav : {data?.fav_team}</h5>
                                        </Col>
                                        <Col xs="6">

                                            <div className="winPerc d-flex align-items-center justify-content-end gap-1">
                                                <h6></h6>
                                                <h6 className="percenBox">{data?.min_rate}</h6>
                                                <h6 className="percenBox">{data?.max_rate}</h6>
                                            </div>



                                        </Col>

                                    </Row>
                                    {/* <Row className="align-items-center ">
                                        <Col xs="6">

                                            <div className="winPerc d-flex align-items-center gap-1">
                                                <h5>6 Over Runs:</h5>
                                                <h6 className="percenBox">45</h6>
                                                <h6 className="percenBox">47</h6>
                                            </div>

                                        </Col>
                                        <Col xs="6">
                                            {
                                            odds_data.odds[1]
                                                ?
                                                <div className="winPerc d-flex align-items-center justify-content-end gap-1">
                                                    <h6>{odds_data.odds[1].RunnerName}:</h6>
                                                    <h6 className="percenBox">{Math.min(odds_data.odds[1].LayPrice1, odds_data.odds[1].LayPrice2, odds_data.odds[1].LayPrice3)}</h6>
                                                    <h6 className="percenBox">{Math.min(odds_data.odds[1].BackPrice1, odds_data.odds[1].BackPrice2, odds_data.odds[1].BackPrice3)}</h6>
                                                </div>
                                                :
                                                null
                                        }
                                        </Col>
                                        <Col xs="12">
                                            <div className="border-bottom my-2"></div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center ">
                                        <Col xs="6">
                                            <h5>1st Inning's Total Runs:</h5>
                                        </Col>
                                        <Col xs="6">

                                            {
                                            odds_data.odds[2]
                                                ?
                                                <div className="winPerc d-flex align-items-center justify-content-end gap-1">
                                                    <h6>{odds_data.odds[2].RunnerName}:</h6>
                                                    <h6 className="percenBox">{Math.min(odds_data.odds[2].LayPrice1, odds_data.odds[2].LayPrice2, odds_data.odds[2].LayPrice3)}</h6>
                                                    <h6 className="percenBox">{Math.min(odds_data.odds[2].BackPrice1, odds_data.odds[2].BackPrice2, odds_data.odds[2].BackPrice3)}</h6>
                                                </div>
                                                :
                                                null
                                        }

                                        </Col>
                                    </Row> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>


                <section className="mt-2  scoreboard">
                    <Container fluid>
                        <Row className="gy-1 mb-4">
                            <Col xs="12" md="6">
                                <div className="bg-white h-100 mb-1 p-1 p-md-3 rounded-2">
                                    <table className="table scorecardTable mb-1">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "40%" }}>Batter</th>
                                                <th style={{ width: "12%" }}>R</th>
                                                <th style={{ width: "12%" }}>B</th>
                                                <th style={{ width: "12%" }}>4s</th>
                                                <th style={{ width: "12%" }}>6s</th>
                                                <th style={{ width: "12%" }}>SR</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data["batsman"] && data["batsman"].length > 0 ?
                                                data["batsman"].map((item, index) => (
                                                    <tr>
                                                        <td>{item.name}</td>
                                                        <td>{item.run}</td>
                                                        <td>{item.ball}</td>
                                                        <td>{item.fours}</td>
                                                        <td>{item.sixes}</td>
                                                        <td>{item.strike_rate}</td>

                                                    </tr>
                                                ))
                                                :
                                                null
                                            }

                                        </tbody>
                                    </table>
                                    Partner Ship :  {data?.partnership?.ball}({data?.partnership?.run})
                                </div>
                            </Col>
                            <Col xs="12" md="6">
                                <div className="bg-white  h-100 mb-1 p-1 p-md-3 rounded-2">
                                    <table className="table scorecardTable mb-1">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "40%" }}>Bowler</th>
                                                <th style={{ width: "12%" }}>O</th>
                                                <th style={{ width: "12%" }}>R</th>
                                                <th style={{ width: "12%" }}>Wkt</th>
                                                <th style={{ width: "12%" }}>Eco</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data?.bolwer
                                                    ?
                                                    <tr>
                                                        <td>{data?.bolwer.name}</td>
                                                        <td>{data?.bolwer.over}</td>
                                                        <td>{data?.bolwer.run}</td>
                                                        <td>{data?.bolwer.wicket}</td>
                                                        <td>{data?.bolwer.economy}</td>
                                                    </tr>

                                                    :
                                                    null

                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                            <Col xs="12" md="6">
                                <div className="bg-white mt-md-2 h-100  p-1 p-md-3 rounded-2">
                                    <table className="table scorecardTable mb-1">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "40%" }}>Yet To Bat</th>
                                                <th style={{ width: "12%" }}></th>
                                                <th style={{ width: "12%" }}></th>
                                                <th style={{ width: "12%" }}></th>
                                                <th style={{ width: "12%" }}></th>
                                                <th style={{ width: "12%" }}></th>
                                                <th style={{ width: "12%" }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>

                                                {
                                                    data["yet_to_bet"] && data["yet_to_bet"].length > 0 ?
                                                        data["yet_to_bet"].map((item, index) => (
                                                            <td>{item}</td>
                                                        ))
                                                        :
                                                        null
                                                }
                                                {/* <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td> */}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                            <Col xs="12" md="6">
                                <div className="bg-white mt-md-2 h-100  p-1 p-md-3 rounded-2">
                                    <table className="table scorecardTable mb-1">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "100%" }}>Last 6 balls</th>
                                            </tr>
                                        </thead>
                                    </table>

                                    <div className="overs my-1  d-flex align-items-center">



                                        {
                                            data.last36ball && data.last36ball.length > 0 ?
                                                data.last36ball.map((item) => {
                                                    let bgColor;
                                                    let textColor;
                                                    let box_shadow;
                                                    switch (item) {
                                                        case "W":
                                                            bgColor = "red";
                                                            textColor = "white";
                                                            break;
                                                        case "6":
                                                            bgColor = "green";
                                                            textColor = "white";
                                                            break;
                                                        case "4":
                                                            bgColor = "green";
                                                            textColor = "white";
                                                            break;
                                                        default:
                                                            bgColor = "#fefffe";
                                                            textColor = "black";
                                                            box_shadow = "0 0 2px";
                                                    }
                                                    return (
                                                        <h5
                                                            className="ball me-2 my-1"
                                                            style={{
                                                                backgroundColor: bgColor,
                                                                color: textColor,
                                                                boxShadow: box_shadow,
                                                            }}
                                                        >
                                                            {item}
                                                            {/* <label>{item.ocn}</label> */}

                                                        </h5>
                                                    );
                                                })
                                                : null
                                        }
                                        {/* <h4 className="mb-0  sum"> = {sum}</h4> */}

                                        {/* <h4 className="limitovers mb-0 ms-2">Overs {data.result.cm.scov}</h4>  */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>


            </div>
            :
            null


    )
}

export default ScoreBoardExtended