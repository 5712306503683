import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  EffectFade,
  Autoplay,
  Navigation,
  Pagination,
} from "swiper";

import "swiper/swiper-bundle.css";

import { Link } from "react-router-dom";
import { BASE_URL } from "../Apis";

SwiperCore.use([EffectFade, Autoplay, Navigation, Pagination]);

function Celebrities(props) {
  const slides = [
    {
      id: 1,
      video:
        "https://tamashabook.com/storage/app/public/uploads/E7BpArc14Ey65APFOrocvK81OK3KqK7qDMgxGuPo.mp4",
    },
    {
      id: 2,
      video:
        "https://tamashabook.com/storage/app/public/uploads/Syb7DW2gpYfkaQeafMadMpAcakOixM9S1E47QhSU.mp4",
    },
    {
      id: 3,
      video:
        "https://tamashabook.com/storage/app/public/uploads/SRV2GQPYjrEXWVPuyepsBSH7O6VA12uHGBt1aQ8c.mp4",
    },
    {
      id: 4,
      video:
        "https://tamashabook.com/storage/app/public/uploads/qXm2oe2humkPCwsh3ntq0lrsUmx4MBrTeeG2ggdp.mp4",
    },
    {
      id: 5,
      video:
        "https://tamashabook.com/storage/app/public/uploads/kmUgLAsdPLtFtEzsvBaAWqpQJ7OqyIzldZE0Bgyw.mp4",
    },
    {
      id: 6,
      video:
        "https://tamashabook.com/storage/app/public/uploads/UpEeefIRzXxDYyMc9v9h2hgVWk9CitbrtOldthKo.mp4",
    },
    {
      id: 7,
      video:
        "https://tamashabook.com/storage/app/public/uploads/E7BpArc14Ey65APFOrocvK81OK3KqK7qDMgxGuPo.mp4",
    },
  ];
  return (
    <>
      <Container fluid className="mt-2">
        <Row>
          <Col md="12">
            {/* <div className="">
              <h4 className="heading"></h4>

            </div> */}

            <div className="d-flex align-items-center justify-content-center">
              <h4
                className="heading bg-white my-2 rounded-4 text-dark text-center p-2 p-md-3 "
                style={{ width: "max-content" }}
              >
                CELEBRITIES & INFLUENCERS
              </h4>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="px-md-0">
        <Swiper
          spaceBetween={10}
          slidesPerView={6}
          loop={true}
          autoplay={{ delay: 4000 }}
          breakpoints={{
            0: {
              slidesPerView: 3,
              transitionDuration: 2000,
            },
            991: {
              slidesPerView: 7,
              transitionDuration: 2000,
            },
          }}
        >
          {props.data.map((slide) => (
            <SwiperSlide key={slide.id}>
              <div className="launch-card">
                <div className="launch-thumb">
                  <video
                    controls
                    className="rounded"
                    width="100%"
                    height="100%"
                  >
                    <source src={BASE_URL + slide.image} type="video/mp4" />
                  </video>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </>
  );
}

export default Celebrities;
