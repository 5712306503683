import React, { useEffect } from "react";

function CalculateSum(runs) {
  const sum = runs.reduce((accumulator, current) => {
    if (typeof current.ball === "number") {
      return accumulator + current.ball;
    } else {
      return accumulator;
    }
  }, 0);

  useEffect(() => {
    console.log(sum);
  }, [sum]);

  return sum;
}

export default CalculateSum;
