
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home';
import ScoreBoard from './Component/ScoreBoard';


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/scorebaord/:id' element={<ScoreBoard />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
