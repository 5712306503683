import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import pyment from "../image/pyment.jpg";
import kotak from "../image/kotak.png";
import paytm from "../image/paytm.png";
import master_Card from "../image/master_card.png";
import visa from "../image/Visa.png";
import hdfc from "../image/hdfc.png";
import sbi from "../image/Sbi.png";
import skrill from "../image/Skrill.png";
import neteller from "../image/neteller.png";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  EffectFade,
  Autoplay,
  Navigation,
  Pagination,
} from "swiper";

import "swiper/swiper-bundle.css";

import { Link } from "react-router-dom";
import { BASE_URL } from "../Apis";

SwiperCore.use([EffectFade, Autoplay, Navigation, Pagination]);

function PaymentSection(props) {
  const payment = [
    {
      img: kotak,
    },
    {
      img: paytm,
    },
    {
      img: master_Card,
    },
    {
      img: visa,
    },
    {
      img: hdfc,
    },
    {
      img: sbi,
    },
    {
      img: skrill,
    },
    {
      img: neteller,
    },
    {
      img: kotak,
    },
  ];
  return (
    <>
      <section className="payment-sec py-0 bg-white">
        <Container>
          <div className="inner-border py-1">
            <Row className=" align-items-center">
              <Col lg="1" md="10" className="col-3 ps-0 px-0">
                <div className="pament-text">
                  <img src={pyment} />
                </div>
              </Col>
              <Col lg="11" md="10" className="col-9 px-0">
                <div className="casio-inner">
                  <Swiper
                    spaceBetween={0}
                    slidesPerView={6}
                    loop={true}
                    autoplay={{ delay: 4000 }}
                    breakpoints={{
                      0: {
                        slidesPerView: 4,
                        transitionDuration: 2000,
                      },
                      991: {
                        slidesPerView: 9,
                        transitionDuration: 2000,
                      },
                    }}
                  >
                    {props.data.map((item, index) => (
                      <SwiperSlide key={index}>
                        <Link to={item.link}>
                          <img src={BASE_URL+ item.image} />
                        </Link>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <div
        class="py-5 d-md-none d-block"
        style={{ backgroundColor: "#000" }}
      ></div>
    </>
  );
}

export default PaymentSection;
